

















import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import DadosNome from '@/components/dados/DadosNome.vue';
import DadosEmail from '@/components/dados/DadosEmail.vue';
import DadosDdd from '@/components/dados/DadosDdd.vue';
import DadosTelefone from '@/components/dados/DadosTelefone.vue';
import DadosProsseguir from '@/components/dados/DadosProsseguir.vue';

@Component({
  components: {
    DadosNome,
    DadosEmail,
    DadosTelefone,
    DadosDdd,
    DadosProsseguir,
  },
})
export default class DadosCliente extends Vue {
  public valid = false;

  public mounted() {
    this.$store.direct.commit.SET_SIDEBAR_SHOWING(false);
    this.$store.direct.commit.SET_SIDEBAR_ENABLED(false);

    window.funnelytics.functions.step();
  }
}
